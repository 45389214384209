<template>
  <router-view />
</template>

<script>
  import Api from './services/Api'
  import ChatApi from './services/ChatApi'
  import Utils from './services/Utils'
  import moment from 'moment'

  window.dashboardVersion = '2024-09-17 16:30'

  export default {
    name: 'App',
    beforeMount: function () {
      window.language = 'pt'
      window.datepickerLanguage = 'pt-PT'
      Utils.clearStorage()
      this.checkOpenUrlNewPage()
      window.cachePhotos = {}
      window.diffUtcHours = this.getDiffUtcHours()
      window.isMobile = Utils.isMobile()
      window.strings = {}

      // Theme
      const cacheTheme = localStorage.getItem('dashboard_theme')
      this.$vuetify.theme.dark = cacheTheme !== 'light' ? true : false

      window.addEventListener('beforeunload', function () {
        if (window.chatPage && !window.chatPage.closed) {
          window.chatPage.close()
        }
        if (Utils.getUser()) {
          Api.logout(function () { })
        }
        Utils.clearStorage()
        return true
      })

      this.startChatNewMessagesInterval()
    },
    methods: {
      checkOpenUrlNewPage: function () {
        try {
          if (localStorage.getItem('open_url_new_page')) {
            const data = JSON.parse(localStorage.getItem('open_url_new_page'))
            localStorage.removeItem('open_url_new_page')
            if (data && data.data) {
              window.urlToOpenAfterLogin = data.url
              window.dashboardSessionStorage = data.data
            }
          }
        } catch { }
      },
      getDiffUtcHours: function () {
        const format = 'YYYY-MM-DDTHH:mm:ss'
        const localTime = moment(moment().format(format), format)
        const utcTime = moment(moment.utc().format(format), format)
        return localTime.diff(utcTime, 'hours')
      },
      startChatNewMessagesInterval: async function () {
        window.chatNewMessagesPromise = setInterval(function () {
          const ptUser = Utils.getUser()
          if (ptUser && ptUser.configurations.menu_items.indexOf('chat') > -1 && ptUser && ptUser.chat_id) {
            ChatApi.getChatNewMessagesCount(ptUser.chat_id, function (response) {
              if (response.success) {
                Utils.refreshNewMessagesCounter(response.data.unread_count)
              }
            })
          }
        }, 30000)
      },
    },
  }
</script>
